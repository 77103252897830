import React from "react";

import Header from "./Header"
import './Main.css'

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

import { auth, db } from "./firebase";

import axios from 'axios';

import { collection, getDocs, where, query, getDoc, doc } from 'firebase/firestore/lite';
 
function Team () {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const [userData, setUserData] = React.useState({});
    const [orgData, setOrgData] = React.useState({});
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [slackMembers, setSlackMembers] = React.useState([]);
 
    const [spinner, setSpinner] = React.useState(true);

    const fetchUserData = async () => {
        try {

            const docRef = doc(db, "admins", user.uid);
            const docSnap = await getDoc(docRef);

            const data = docSnap.data();

            setUserData(data);

            if (data.organization) {
                const members = [];
                const onboarded = [];
                const membersQuery = await getDocs(collection(db, `organizations/${data.organization}/members`));
                membersQuery.forEach(
                  (item) => {
                    const memberData = item.data();
                    if ("full_name" in memberData) {
                      onboarded.push(item.data())  
                    } else {
                      members.push(item.data());
                    }
                  }
                )

                // add onboarded members to the end of the list
                members.push(...onboarded);
                
                
                const response = await axios.get('https://api.getelessar.com/app/slack_users', { params: { org_id: data.organization } });
                const slack_members = response.data.slack_members;

                setOrgMembers(members);
                setSlackMembers(slack_members);
            }

            setSpinner(false);
        } catch (err) {
            console.error(err);
        }
    };


    const setLink = async (index, value) => {
      const result = slackMembers.find(obj => obj.full_name === value);
      if (result){
        const data = {
            org_id: userData.organization, 
            login: orgMembers[index].login,
            slack_uid: result.id,
            slack_name: result.full_name,
            slack_email: result.email,
            slack_image: result.image
        };
        const response = await axios.post("https://api.getelessar.com/app/link_member", data);
        console.log(response);
        if (response.status == 200) {
            //fetchUserData()
        }
      }
    };

    const navigateDone = () =>{ 
      navigate("/");
    }

  
    React.useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");

      fetchUserData();
    }, [user, loading]);
  
    return (
      <>
        {!spinner &&
        <Header userName={userData.name}/>
        }
        <div className="mainContainer">
            {spinner ? <img className="spinner" src={require('./assets/Spinner.gif')} alt="loading..."></img> : 
            <div className="centeredArea">
                <div>
                    <div className="sectionHeader">
                        <h1 className="cardHeader">Manage Team</h1>
                    </div>

                    
                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Link Team Members</h3>
                        </div>
                        
                        <div className="integrationDivider"></div>

                        <div className="teaminfodiv">
                          <p>For each GitHub user below, start entering their name in the Enter Slack User field. It will autocomplete and link the user to the platform. Complete as many as you can and click Done — users can also self-onboard themselves.</p>
                        </div>

                        <div className="teamDiv">
                        {orgMembers.map((member,index) => (
                          <div className="teamCard">
                              <div><h4>{member.login}</h4></div>
                              <div className="codeRegion">

                              <input className="setupInput" placeholder="Enter Slack User" type="text" list={"data_" + index} defaultValue={member.full_name ? member.full_name : ''} onChange={(e) => setLink(index, e.target.value)} />
                              <datalist id={"data_" + index}>
                                {slackMembers.map((item, key) =>
                                  <option key={index + "_" + key} value={item.full_name} />
                                )}
                              </datalist>
                  
                              </div>
                          </div>
                          
                        ))}
                            
                        </div>

                        
                                              
                    </div> 

                    <div className="doneRegion">
                                <button className="doneButton" onClick={navigateDone}>Done</button>
                            </div>


                </div>

            </div>
            }
        </div>

        {!spinner &&
        <footer className="footer">
            <p className="footerText">© 2023 Elessar Technologies Inc.</p>
        </footer>
        }
        
        
      </>
    );
}
  

export default Team;