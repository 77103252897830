import React from "react"
import './Header.css'
import { useNavigate } from "react-router";

import { Popover } from 'react-tiny-popover'

import { logoutFirebase } from "./firebase";

function Header (props) {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const navigate = useNavigate();

    const navigateHome = () =>{ 
        navigate("/");
    }
    const navigateAccount = () =>{
        navigate("/account");
    }

    return (
    <>
        <div className="header">
           <div className="logoContainer">
                <img className="logoImg" onClick={navigateHome} src={require('./assets/elessar-new-logo.png')}></img>
           </div>
            
           {(props.userName !== "" && props.userName !== undefined) &&

            <Popover
            isOpen={isPopoverOpen}
            positions={['bottom']} // preferred positions by priority
            content={
            <div className="dropCont">
                <div className="dropdown">
                    <button onClick={navigateAccount} className="accountButton">Manage account</button>
                </div>
                <div className="dropdown">
                    <button onClick={logoutFirebase} className="logoutButton">Log out</button>
                </div>
            </div>
            
            }
            >
                <div onClick={() => setIsPopoverOpen(!isPopoverOpen)} className="userContainer">
                        <div className="userProfile">{props.userName[0]}</div>
                        <p className="userName">{props.userName}</p>
                </div> 
            </Popover>
           }
        </div>
    </>
    );
}

export default Header;

