import { initializeApp} from 'firebase/app';

import { getFirestore, collection, getDocs, addDoc, where, query, doc, getDoc, setDoc } from 'firebase/firestore/lite';

import axios from 'axios';

import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  signInWithPopup, 
  GoogleAuthProvider} from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyA5D72FqqbMuAm1opYKq4YTOZ90kFX59Cg",
    authDomain: "elessar-cc70e.firebaseapp.com",
    projectId: "elessar-cc70e",
    storageBucket: "elessar-cc70e.appspot.com",
    messagingSenderId: "329297848793",
    appId: "1:329297848793:web:cd1f8d0860e3039a9111c8",
    measurementId: "G-SLZ1SW45HZ"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

const googleProvider = new GoogleAuthProvider();


const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    const data = { user: user };
    const response = await axios.post("https://api.getelessar.com/app/sign_in_google", data)
    console.log(response);

  } catch (err) {
    console.error(err);
  }
};

const signIn = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    const data = { user: user };
    const response = await axios.post("https://api.getelessar.com/app/register_email_pass", data)
    console.log(response);
  } catch (err) {
    console.error(err);
    alert("Error: User already exists");
  }
};

const sendResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
  }
};


const logoutFirebase = () => {
  auth.signOut();
};

const deleteUser = async () => {
  try {
    await auth.currentUser.delete();
  } catch (err) {
    console.error(err);
  }
};

export {
  auth,
  db,
  signInWithGoogle,
  signIn,
  registerWithEmailAndPassword,
  sendResetEmail,
  logoutFirebase,
  deleteUser
};