import React from "react";

import Header from "./Header"
import './Main.css'

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

import { auth, db, logoutFirebase } from "./firebase";

import axios from 'axios';

import { collection, getDocs, where, query, getDoc, doc } from 'firebase/firestore/lite';

import { deleteUser } from "./firebase";

 
function Account () {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const [userData, setUserData] = React.useState({});
 
    const [spinner, setSpinner] = React.useState(true);

    const fetchUserData = async () => {
        try {

            const docRef = doc(db, "admins", user.uid);
            const docSnap = await getDoc(docRef);

            const data = docSnap.data();

            setUserData(data);

            setSpinner(false);
        } catch (err) {
            console.error(err);
        }
    };

    const manageBilling = async () => {
        const data = {
            org_id: userData.organization, 
            admin_id: user.uid
        };
        axios.post('https://api.getelessar.com/app/manage_billing', data)
            .then(response => {
                console.log(response);
                if (response.data.url) {
                    window.location.href = response.data.url;
                }
            })
            .catch(error => {
                console.error('Error making POST request:', error);
            });
    };

    const deleteAccount = async () => {
        // alert confirmation to delete then send delete request to backend
        if (window.confirm("Are you sure? This will delete all data and cannot be undone.")) {
            // send delete request to backend
            const data = {
                org_id: userData.organization, 
                admin_id: user.uid
            };
            const response = await axios.post("https://api.getelessar.com/app/delete_account", data);
            console.log(response);
            if (response.status === 200) {
                logoutFirebase();
                //navigate("/register");
            }
        }

    };


    const navigateDone = () =>{ 
      navigate("/");
    }

  
    React.useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");

      fetchUserData();
    }, [user, loading]);
  
    return (
      <>
        {!spinner &&
        <Header userName={userData.name}/>
        }
        <div className="mainContainer">
            {spinner ? <img className="spinner" src={require('./assets/Spinner.gif')} alt="loading..."></img> : 
            <div className="centeredArea">
                <div>
                    <div className="sectionHeader">
                        <h1 className="cardHeader">Manage Account</h1>
                    </div>
                    
                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Manage Billing</h3>
                            <button className="billingButton" onClick={manageBilling}>Manage</button>
                        </div>               
                    </div> 
                    
                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Delete Account</h3>
                            <button className="deleteButton" onClick={deleteAccount}>Delete</button>
                        </div>           
                    </div> 

                    <div className="doneRegion">
                                <button className="doneButton" onClick={navigateDone}>Done</button>
                            </div>


                </div>

            </div>
            }
        </div>

        {!spinner &&
        <footer className="footer">
            <p className="footerText">© 2023 Elessar Technologies Inc.</p>
        </footer>
        }
        
        
      </>
    );
}
  

export default Account;