import React from "react";

import Header from "./Header"
import './Main.css'

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

import { auth, db } from "./firebase";

import Switch from "react-switch";

import axios from 'axios';

import { collection, getDocs, where, query, getDoc, doc } from 'firebase/firestore/lite';
 
function Main () {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const [userData, setUserData] = React.useState({});
    const [orgData, setOrgData] = React.useState({});
    const [orgName, setName] = React.useState("");
    const [role, setRole] = React.useState("");
    const [numOnboard, setNumOnboard] = React.useState(0);
    const [numNotOnboard, setNumNotOnboard] = React.useState(0);
    const [repos, setRepos] = React.useState([]);
    const [managers, setManagers] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [managerName, setManagerName] = React.useState("");
    const [managerID, setManagerID] = React.useState("");
    const [addDisabled, setAddDisabled] = React.useState(true);
    const [linearMembers, setLinearMembers] = React.useState([]);
    const [disabledButtons, setDisabledButtons] = React.useState([]);

    const [ephemeral, setEphemeral] = React.useState(true);
    const [digest, setDigest] = React.useState(true);
    const [management, setManagement] = React.useState(false);

    const [spinner, setSpinner] = React.useState(true);
    //const [retry_count, setRetryCount] = React.useState(0);

    const fetchUserData = async (retries) => {
        try {

            const docRef = doc(db, "admins", user.uid);
            const docSnap = await getDoc(docRef);

            const data = docSnap.data();

            setUserData(data);

            if (data.organization) {
                const docRef = doc(db, "organizations", data.organization);
                const docSnap = await getDoc(docRef);

                const orgData = docSnap.data();
                
                setOrgData(orgData);

                if (orgData.notion_workspace_id) {
                    const membersRef = collection(docRef, "members");
                    const membersSnap = await getDocs(membersRef);

                    // Count the onboarded members
                    let onboardedMembersCount = 0;
                    let notOnboardedMembersCount = 0;
                    membersSnap.forEach(memberDoc => {
                        const memberData = memberDoc.data();
                        if (memberData.slack_uid) {
                            onboardedMembersCount++;
                        } else {
                            notOnboardedMembersCount++;
                        }
                    });

                    setNumOnboard(onboardedMembersCount);
                    setNumNotOnboard(notOnboardedMembersCount);

                    const reposRef = collection(docRef, "repos");
                    const reposSnap = await getDocs(reposRef);

                    let repo_names = [];
                    reposSnap.forEach(repoDoc => {
                        repo_names.push(repoDoc.id);
                    });

                    setRepos(repo_names);

                    if (orgData.manager_reports) {
                        setManagement(true);
                        const members = [];
                        const managers = [];
                        membersSnap.forEach(
                            (item) => {
                                const memberData = item.data();
                                if ("full_name" in memberData) {
                                    members.push({full_name: memberData.full_name, id: item.id});
                                    if ("manager" in memberData) {
                                        managers.push({full_name: memberData.full_name, id: item.id});
                                    }
                                }
                            }
                        );
                        setMembers(members);
                        setManagers(managers);
                        if (orgData.unmatched_linear_users) {
                            const linearMembers = [];
                            const disabledButtons = [];
                            orgData.unmatched_linear_users.forEach(
                                () => {
                                    linearMembers.push("");
                                    disabledButtons.push(true);
                                }
                            );
                            setLinearMembers(linearMembers);
                            setDisabledButtons(disabledButtons);
                        }
                    }
                }
            }

            setSpinner(false);


            
        } catch (err) {
            console.log(err);
            if (retries < 3){
                await new Promise(resolve => setTimeout(resolve, 1000 + retries * 4000));
                fetchUserData(retries + 1);
            }
        }
    };

    const createOrgStartTrial = async () => {
        if (orgName !== "") {
            const data = { 
                uid: userData.uid,
                org_name: orgName
             };
            axios.post('https://api.getelessar.com/app/create_org_start_trial', data)
                .then(response => {
                    console.log(response);
                    if (response.data.url) {
                        window.location.href = response.data.url;
                    }
                })
                .catch(error => {
                    console.error('Error making POST request:', error);
                });
            /*    
            const response = await axios.post("https://api.getelessar.com/app/create_org_start_trial", data);
            console.log(response);
            if (response.status === 303) {
                setName("");
                setRole("");
                fetchUserData(0);
            }*/
        } else {
            alert("Please enter an organization name");
        }
    };

    const navigateTeam = () =>{ 
        navigate("/team");
      }

    const handleManagementToggle = async (managementState) => {
        setSpinner(true);
        const data = { 
            uid: userData.uid,
            org_id: userData.organization
         };
        if (!managementState) {
            const response = await axios.post("https://api.getelessar.com/app/turn_on_management", data);
            console.log(response);
            if (response.status == 200 && members.length == 0) {
                const members = [];
                const managers = [];
                const membersQuery = await getDocs(collection(db, `organizations/${userData.organization}/members`));
                membersQuery.forEach(
                    (item) => {
                        const memberData = item.data();
                        if ("full_name" in memberData) {
                            members.push({full_name: memberData.full_name, id: item.id});
                            if ("manager" in memberData) {
                                managers.push({full_name: memberData.full_name, id: item.id});
                            }
                        }
                    }
                );
                setMembers(members);
                setManagers(managers);
            }
        } else {
            const response = await axios.post("https://api.getelessar.com/app/turn_off_management", data);
            console.log(response);
        }
        setSpinner(false);
    };

    const fixLinear = async (index) => {
        setSpinner(true);
        const data = {
            uid: userData.uid,
            org_id: userData.organization,
            member_id: linearMembers[index],
            linear_id: orgData.unmatched_linear_users[index].id
        };
        const response = await axios.post("https://api.getelessar.com/app/fix_linear", data);
        console.log(response);
        if (response.status == 200) {
            const docRef = doc(db, "organizations", userData.organization);
            const docSnap = await getDoc(docRef);
            const orgData = docSnap.data();
            setOrgData(orgData);
        }
        setSpinner(false);
    };


    const identifyLinear = async (index, value) => {
        const result = members.find(obj => obj.full_name === value);
        if (result){
            const newLinear = [...linearMembers];
            newLinear[index] = result.id;
            setLinearMembers(newLinear);
            const newDisabled = [...disabledButtons];
            newDisabled[index] = false;
            setDisabledButtons(newDisabled);
        }
        else {
            const newLinear = [...linearMembers];
            newLinear[index] = "";
            setLinearMembers(newLinear);
            const newDisabled = [...disabledButtons];
            newDisabled[index] = true;
            setDisabledButtons(newDisabled);
        }
    };
    
    const identifyManager = async (value) => {
        setManagerName(value);
        const result = members.find(obj => obj.full_name === value);
        if (result){
            setManagerID(result.id);
            setAddDisabled(false);
        }
        else {
            setManagerID("");
            setAddDisabled(true);
        }
    };


    const addManager = async () => {
        setSpinner(true);
        const data = { 
            uid: userData.uid,
            org_id: userData.organization,
            member_id: managerID
         };
        const response = await axios.post("https://api.getelessar.com/app/add_manager", data);
        console.log(response);
        if (response.status == 200) {
            const managers = [];
            const membersQuery = await getDocs(collection(db, `organizations/${userData.organization}/members`));
            membersQuery.forEach(
                (item) => {
                    const memberData = item.data();
                    if ("full_name" in memberData) {
                        if ("manager" in memberData) {
                            managers.push({full_name: memberData.full_name, id: item.id});
                        }
                    }
                }
            );
            setManagers(managers);
        }
        setSpinner(false);
    };

    const removeManager = async (id) => {
        setSpinner(true);
        const data = { 
            uid: userData.uid,
            org_id: userData.organization,
            member_id: id
         };
        const response = await axios.post("https://api.getelessar.com/app/remove_manager", data);
        console.log(response);
        if (response.status == 200) {
            const managers = [];
            const membersQuery = await getDocs(collection(db, `organizations/${userData.organization}/members`));
            membersQuery.forEach(
                (item) => {
                    const memberData = item.data();
                    if ("full_name" in memberData) {
                        if ("manager" in memberData) {
                            managers.push({full_name: memberData.full_name, id: item.id});
                        }
                    }
                }
            );
            setManagers(managers);
        }
        setSpinner(false);
    };

  
    React.useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");

      fetchUserData(0);
    }, [user, loading]);
  
    return (
      <>
        {!spinner &&
        <Header userName={userData.name ? userData.name : userData.email}/>
        }
        <div className="mainContainer">
            {spinner ? <img className="spinner" src={require('./assets/Spinner.gif')} alt="loading..."></img> : 
            <div className="centeredArea">
                {orgData.notion_workspace_id &&
                <div>

                    <div className="sectionHeader">
                        <h1 className="cardHeader">Dashboard</h1>
                        <p className="planText"><strong>Plan:</strong> Elessar Pro Free Trial</p>
                    </div>

                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">{orgData.org_name} Organization</h3>
                            <span className="activePill">ACTIVE</span>
                        </div>

                        <div className="integrationDivider"></div>

                        <div className="dashCard">
                            <div className="dashRegion">
                                <p className="onnumtext"><span className="numspan">{numOnboard}</span> onboarded members {numNotOnboard > 0 && <span className="notonboard">(<b>{numNotOnboard} not onboarded</b>)</span>}</p>
                                <button className="onboardMembers" onClick={navigateTeam}>Onboard Members</button>
                            </div>
                        </div>

                        <div className="integrationDivider"></div>

                        <div className="dashCard">
                            <div className="dashRegion">
                                <p className="onnumtext">
                                {repos.length === 0
                                    ? "Notion"
                                    : (
                                    <>
                                        Notion (repos:{" "}
                                        {repos.map((repo, index) => (
                                        <React.Fragment key={index}>
                                            <b>{repo}</b>
                                            {index !== repos.length - 1 ? ", " : ""}
                                        </React.Fragment>
                                        ))}
                                        )
                                    </>
                                    )}
                                </p>
                                
                                <div className="reportFlex">
                                    <a href={"https://www.notion.so/" + orgData.notion_template_id.replace(/-/g, "")} className="allReports">All Reports</a>
                                    <span className="bi bi-check-circle checkComplete"></span>
                                </div>
                            </div>
                        </div>

                        <div className="integrationDivider"></div>

                        <div className="dashCard">
                            <div className="dashRegion">
                                <p className="onnumtext">Slack pull request ephemeral channels</p>
                                <Switch
                                    className="react-switch"
                                    onChange={() => setEphemeral(!ephemeral)}
                                    checked={ephemeral}
                                    onColor="#10a37f"
                                    disabled
                                    checkedIcon={false}
                                    aria-label="ephemeral-channels"
                                />
                            </div>
                        </div>

                        <div className="integrationDivider"></div>

                        <div className="dashCard">
                            <div className="dashRegion">
                                <p className="onnumtext">Engineering daily emails</p>
                                <Switch
                                    className="react-switch"
                                    onChange={() => setDigest(!digest)}
                                    checked={digest}
                                    onColor="#10a37f"
                                    disabled
                                    checkedIcon={false}
                                    aria-label="daily-digests"
                                />
                            </div>
                        </div>

                        <div className="integrationDivider"></div>

                        <div className="dashCard">
                            <div className="dashRegion">
                                <p className="onnumtext">Management-level reporting</p>
                                <Switch
                                    className="react-switch"
                                    onChange={() => {handleManagementToggle(management); setManagement(!management);}}
                                    checked={management}
                                    aria-label="manager-level"
                                    onColor="#10a37f"
                                    checkedIcon={false}
                                />
                            </div>
                        </div>
                        
                        {management &&
                        <div className="addManagerSection">
                            <div className="addManager">
                                <h3 className="cardHeaderM">Add a manager</h3>
                                <div className="addManagerFlex">
                                    <input className="addManagerInput" placeholder="Enter Onboarded Member" type="text" list={"data_managers"} value={managerName} onChange={(e) => identifyManager(e.target.value)} />
                                    <datalist id={"data_managers"}>
                                        {members.map((item, key) =>
                                        <option key={key} value={item.full_name} />
                                        )}
                                    </datalist>
                                    <button className="addManagerButton" disabled={addDisabled} onClick={addManager}>Add</button>
                                </div>
                            </div>
                            <div className="managerList">
                                <h3 className="cardHeaderM">Current management</h3>
                                <div className="managerListFlex">
                                    {managers.map((manager, index) => (
                                        <div className="managerItem" key={index}>
                                            <p className="managerName">{manager.full_name}</p>
                                            <button className="removeManagerButton" onClick={() => removeManager(manager.id)}>Remove</button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        }
                        {management && !orgData.unmatched_linear_users &&
                        <div className="integrationCard">
                            <img className="integrationLogoSmall" src={require('./assets/linear.png')}></img>
                            <div className="integrationInfo">
                                <h3 className="infoHeader">Linear</h3>
                                <div className="infoFlex">
                                    <p className="infoSub">Bring Linear issues to manager emails</p>
                                </div>
                            </div>
                            <div className="integrationConnect">
                                <a href={"https://linear.app/oauth/authorize?response_type=code&client_id=3fc6292be6000c0dfb8097bbe506c9b2&redirect_uri=https%3A%2F%2Fapi.getelessar.com%2Fauth%2Flinear&scope=read&state=" + userData.organization} className="integrationButton">Connect</a>
                            </div>
                        </div>
                        }
                        {management && orgData.unmatched_linear_users &&
                        <div className="dashCard">
                            <div className="dashRegion">
                                <p className="onnumtext">Linear Issues</p>
                                <span className="bi bi-check-circle checkComplete"></span>
                            </div>
                        </div>
                        }
                        {management && orgData.unmatched_linear_users && orgData.unmatched_linear_users.length > 0 &&
                        <div className="fixLinearSection">
                            <div className="linearCenter">
                                <p className="onnumtextL">Fix unlinked Linear users</p>
                                {orgData.unmatched_linear_users.map((user,index) => (
                                    <div className="fixItemFlex">
                                        <p className="linearName">{user.name}</p>

                                        <div className="linearInputFlex">
                                            <input className="linearInput" placeholder="Enter Onboarded Member" type="text" list={"data_" + index} onChange={(e) => identifyLinear(index, e.target.value)} />
                                            <datalist id={"data_" + index}>
                                                {members.map((item, key) =>
                                                <option key={index + "_" + key} value={item.full_name} />
                                                )}
                                            </datalist>
                                            <button className="linearButton" disabled={disabledButtons[index]} onClick={() => fixLinear(index)}>Fix</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                                
                        </div>
                        }        
                        

                        <div className="endPad"></div>

                    </div>
                </div>
                }
                {!orgData.notion_workspace_id &&
                <div>
                    <div className="sectionHeader">
                        <h1 className="cardHeader">Onboarding</h1>
                        <p className="planText"><strong>Plan:</strong> Elessar Pro Free Trial</p>
                    </div>


                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Step 1 — Setup your Organization {orgName != "" && "(" + (orgName) + ")"} {orgData.org_name && "(" + (orgData.org_name) + ")"}</h3>
                            {orgData.org_name &&
                            <span className="bi bi-check-circle checkComplete"></span>
                            }
                        </div>
                        
                        {!orgData.org_name &&
                        <div className="integrationDivider"></div>
                        }
                        {!orgData.org_name &&
                        <div className="questionDiv">
                            <div className="activationRegion">
                                <p className="codeInfo">Organization name:</p>
                                <div className="codeRegion">
                                    <input
                                        id="orgInput"
                                        className="setupInput"
                                        placeholder="Eg. Apple"
                                        type="text"
                                        value={orgName}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                
                            </div>
                            <div className="startRegion">
                                <button className="startButton" onClick={createOrgStartTrial}>Start Trial</button>
                            </div>
                        </div>
                        }                       
                    </div> 

                    

                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Step 2 — Connect GitHub</h3>
                            {orgData.installation_id &&
                            <span className="bi bi-check-circle checkComplete"></span>
                            }
                        </div>
                        
                        {(orgData.org_name && !orgData.installation_id) &&
                        <div className="integrationDivider"></div>
                        }
                        {orgData.org_name && !orgData.installation_id &&
                        <div className="integrationCard">
                            <img className="integrationLogoSmall" src={require('./assets/github_logo.png')}></img>
                            <div className="integrationInfo">
                                <h3 className="infoHeader">GitHub</h3>
                                <div className="infoFlex">
                                    <p className="infoSub">Connect GitHub pull requests with the Elessar platform</p>
                                    <span className="bi bi-info-circle infoCircle"></span>
                                </div>
                            </div>
                            <div className="integrationConnect">
                            
                            <a href={"https://github.com/apps/elessar-for-github/installations/new?&state=" + userData.organization + "_" + userData.uid} className="integrationButton">Connect</a>
                            </div>
                        </div>
                        }
                    </div>
                    

                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Step 3 — Add to Slack</h3>
                            {orgData.slack_team_id &&
                            <span className="bi bi-check-circle checkComplete"></span>
                            }
                        </div>
                        
                        {orgData.org_name && !orgData.slack_team_id &&
                        <div className="integrationDivider"></div>
                        }
                        {orgData.org_name && !orgData.slack_team_id &&
                        <div className="integrationCard">
                            <img className="integrationLogoSmall" src={require('./assets/slack_logo.png')}></img>
                            <div className="integrationInfo">
                                <h3 className="infoHeader">Slack</h3>
                                <div className="infoFlex">
                                    <p className="infoSub">Bring Elessar development visibility to Slack</p>
                                    <span className="bi bi-info-circle infoCircle"></span>
                                </div>
                            </div>
                            <div className="integrationConnect">
                            <a href={"https://slack.com/oauth/v2/authorize?scope=app_mentions%3Aread%2Cchannels%3Ahistory%2Cchannels%3Amanage%2Cchannels%3Aread%2Cchat%3Awrite%2Cchat%3Awrite.public%2Ccommands%2Cgroups%3Ahistory%2Cgroups%3Awrite%2Cim%3Ahistory%2Cim%3Aread%2Cim%3Awrite%2Creactions%3Aread%2Creactions%3Awrite%2Cusers%3Aread%2Cusers%3Aread.email%2Cbookmarks%3Awrite&user_scope=&redirect_uri=https%3A%2F%2Fapi.getelessar.com%2Fauth%2Fslack&client_id=3098529234054.4784091920245&state=" + userData.organization} className="integrationButton">Connect</a>
                            </div>
                        </div>
                        }
                    </div>

                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Step 4 — Onboard Team Members</h3>
                            {orgData.org_name && 
                            <button className="linkButton" onClick={navigateTeam}>Onboard Team</button>
                            }
                        </div>
                    </div>

                    <div className="integrationRegion">
                        <div className="headerRegion">
                            <h3 className="cardHeader">Step 5 — Connect Notion</h3>
                            {orgData.notion_workspace_id &&
                            <span className="bi bi-check-circle checkComplete"></span>
                            }
                        </div>
                        
                        {orgData.org_name && !orgData.notion_workspace_id &&
                        <div className="integrationDivider"></div>
                        }
                        {orgData.org_name && !orgData.notion_workspace_id &&
                        <div className="integrationCard">
                            <img className="integrationLogoSmall" src={require('./assets/notion_logo.png')}></img>
                            <div className="integrationInfo">
                                <h3 className="infoHeader">Notion</h3>
                                <div className="infoFlex">
                                    <p className="infoSub">Bring Elessar auto documentation and reports to Notion</p>
                                    <span className="bi bi-info-circle infoCircle"></span>
                                </div>
                            </div>
                            <div className="integrationConnect">
                                <a href={"https://api.notion.com/v1/oauth/authorize?client_id=3b01be03-50a2-418c-9546-9b29ec9c9ca4&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapi.getelessar.com%2Fauth%2Fnotion&state=" + userData.organization} className="integrationButton">Connect</a>
                            </div>
                        </div>
                        }
                    </div>

                    
                </div>

                }

            </div>
            }
        </div>

        {!spinner &&
        <footer className="footer">
            <p className="footerText">© 2023 Elessar Technologies Inc.</p>
        </footer>
        }
        
        
      </>
    );
}
  

export default Main;