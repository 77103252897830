import React from "react";

import Header from "./Header"
import './Login.css'
import './Main.css'

import showPwdImg from './assets/show-password.svg';
import hidePwdImg from './assets/hide-password.svg';


import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";

import { auth, signIn, signInWithGoogle, registerWithEmailAndPassword } from "./firebase";

 
function Register () {
    const [user, loading, error] = useAuthState(auth);
    const [email, setEmail] = React.useState('');
    const [pwd, setPwd] = React.useState('');
    const [isRevealPwd, setIsRevealPwd] = React.useState(false);

    const navigate = useNavigate();

    const navigateLogin = () =>{ 
      navigate("/login");
    }
  
    React.useEffect(() => {
      if (loading) return;
      if (user) return navigate("/");
    }, [user, loading]);
  
    return (
      <>
        <Header userName=""/>
        <div className="mainContainer">
            <div className="centeredAreaLogin">
                <div className="sectionHeader">
                    <h1 className="cardHeader">Register</h1>
                </div>

                <div className="loginRegion">



                  <div className="loginInterior">

                    <div className="loginInputs">
                      <div className="textRegion">
                          <input 
                              className="setupInput"
                              placeholder="Email"
                              value={email}
                              type="text"
                              onChange={e => setEmail(e.target.value)}
                          />
                      </div>
                      <div className="textRegion">
                          <input 
                              className="setupInput"
                              placeholder="Password"
                              type={isRevealPwd ? "text" : "password"}
                              value={pwd}
                              onChange={e => setPwd(e.target.value)}
                          />
                          <img
                            className="eye"
                            title={isRevealPwd ? "Hide password" : "Show password"}
                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                          />
                      </div>
                      
                    </div>

                    <div className="signreg">
                      <div className="signInBlock">
                        <button className="signInButton" onClick={() => registerWithEmailAndPassword(email, pwd)}>Sign Up</button>
                      </div>
                      <div className="regflex">
                        <p className="noaccount">Have an account?</p>
                        <button className="reglog" onClick={navigateLogin}>Log in</button>
                      </div>
                      
                    </div>
                    
                    
                    
                    <div className="orDivider">
                      <div className="lineDiv"></div>
                      <p className="orText">OR</p>
                      <div className="lineDiv"></div>
                    </div>
                    

                    <div className="googleHolder">
                      <button className="googleButton" onClick={signInWithGoogle}><i className="bi bi-google gIcon"></i> Sign up with Google</button>
                    </div>

                  </div>
                  
                        
                </div>
                
                
            </div>
        </div>
      </>
    );
}
  

export default Register;