//import './App.css';
import Main from './Main'
import Login from './Login'
import Team from './Team'
import Register from './Register'
import Account from './Account'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/" element={<Main />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
